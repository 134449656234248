import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./searchpage.css";
import logo from "../../logo.svg";
import { Link } from "react-router-dom";
import Form1 from "../../components/forms/Form1";
import Form2 from "../../components/forms/Form2";
import Form3 from "../../components/forms/Form3";

import React, { useContext, useState } from "react";
import { MultiStepContext } from "../../StepContext";
import Form9 from "../../components/forms/Form9";

function Search() {
  const { currentStep, filteredUsers } = useContext(MultiStepContext);

  function showStep(step) {
    console.log(step);
    switch (step) {
      case 1:
        return <Form1 />;
      case 2:
        return <Form2 />;
      case 3:
        return <Form3 />;
      case 9:
        return <Form9 />;
    }
  }
  console.log("Context users: ", filteredUsers);
  return (
    <div className="container Form">
      <div className="header">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
      </div>
      <Stepper activeStep={currentStep - 1} orientation="horizontal">
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
      {showStep(currentStep)}
    </div>
  );
}

export default Search;

import React, { useState, useContext } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { MultiStepContext } from "../../StepContext";
import Counter from "./Counter";

export default function Form2() {
  const { setStep, filteredUsers, changeData } = useContext(MultiStepContext);
  const usersSecond = filteredUsers;
  console.log("UserSecond: ", usersSecond);
  const [SecondFilteredUsers, setSecondFilteredUsers] = useState(usersSecond);

  function onChangeValue(event) {
    console.log(event.target.value);
    const selectedType = event.target.value;
    const filtered2 = usersSecond.filter((user) =>
      user.target_audience.includes(selectedType)
    );
    console.log(filtered2);
    setSecondFilteredUsers(filtered2);
  }

  return (
    <div className="container flexColumn">
      <button className="back" onClick={() => setStep(1)}>
        Назад
      </button>
      <h2>Для кого подбираете психотерапевта?</h2>

      <FormControl>
        <RadioGroup>
          <div className="item">
            <FormControlLabel
              value="adult"
              control={<Radio />}
              label="Для себя"
              onChange={onChangeValue}
            />
          </div>
          <div className="item">
            {" "}
            <FormControlLabel
              value="child"
              control={<Radio />}
              label="Для ребенка"
              onChange={onChangeValue}
            />
          </div>
          <div className="item">
            <FormControlLabel
              value="couples"
              control={<Radio />}
              label="Для пары"
              onChange={onChangeValue}
            />
          </div>
          <div className="item">
            <FormControlLabel
              value="family"
              control={<Radio />}
              label="Для семьи"
              onChange={onChangeValue}
            />
          </div>
        </RadioGroup>
      </FormControl>
      <div className="countContainer">
        {" "}
        <Counter filteredUsers={SecondFilteredUsers} />
        <button onClick={() => changeData(3, SecondFilteredUsers)}>
          Далее
        </button>
      </div>
    </div>
  );
}

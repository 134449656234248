import './home.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PsSlider from '../../components/slider/PsSlider';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../components/slider/PsSlider'
import {testimonials} from '../../data'
import Accordions from '../../components/accordion/Accordions';
import { Link } from 'react-router-dom';

export default function Home() {

const [users, setUsers] = useState([]);

const url = "https://jsonplaceholder.typicode.com/users"
  React.useEffect(() => {
    axios.get(url).then((response) => {
      setUsers(response.data);
    });
  }, []);

console.log("users: ", users)

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 4 },
};

  return (
    <>
      <section id="main">
        <div className="container flex">
          <div className="mainLeft">
            <h1>Профессиональные психологи и психотерапевты.</h1>
            <p className="description">
              Онлайн сервис подбора психологов и психотерапевтов
            </p>
            <Link to="/search" ><button>Подобрать специалиста</button></Link>
          </div>
          <div className="mainRight">
            <img src="/img/main.png" alt="" />
          </div>
        </div>
      </section>
      <section id="why">
        <div className="container flex">
          <div className="item">
          <img src="/img/adv_1.svg" alt="" />
            <span className="advText">Удобный формат встреч</span>
            <span className="advDesc">
              Встречи проходят в удобном для вас формате: whatsapp, telegram,
              zoom
            </span>
          </div>
          <div className="item">
          <img src="/img/adv_5.svg" alt="" />
            <span className="advText">Удобный формат встреч</span>
            <span className="advDesc">
              Встречи проходят в удобном для вас формате: whatsapp, telegram,
              zoom
            </span>
          </div>
          <div className="item">
          <img src="/img/adv_3.svg" alt="" />
            <span className="advText">Удобный формат встреч</span>
            <span className="advDesc">
              Встречи проходят в удобном для вас формате: whatsapp, telegram,
              zoom
            </span>
          </div>
          <div className="item">
          <img src="/img/adv_4.svg" alt="" />
            <span className="advText">Удобный формат встреч</span>
            <span className="advDesc">
              Встречи проходят в удобном для вас формате: whatsapp, telegram,
              zoom
            </span>
          </div>
        </div>
      </section>
      <section id="psychologists">
     <div className='container'><h2>Выберите психолога</h2></div>
          
          <div className="itemsWrapper">
           <PsSlider/>
          </div>
       
      </section>
      <section id="orderSect">
        <div className="container">
          <h2>Как записаться</h2>
          <div className="itemsWrapper flex">
            <div className="item">
              <h3>Оставить заявку на сайте</h3>
              <img className="itemIcon" src='/img/step1.png' />
              <img className="itemNum"  src='/img/01.png' />
            </div>
            <div className="item">
            <h3>Оставить заявку на сайте</h3>
            <img className="itemIcon"  src='/img/step2.png' />
            <img className="itemNum"   src='/img/02.png' />
            </div>
            <div className="item">
            <h3>Оставить заявку на сайте</h3>
            <img className="itemIcon"  src='/img/step3.png' />
            <img className="itemNum"   src='/img/03.png' />
            </div>
            <div className="item">
            <h3>Оставить заявку на сайте</h3>
            <img className="itemIcon"  src='/img/step4.png' />
            <img className="itemNum"   src='/img/04.png' />
            </div>
          </div>
          <button>Записаться</button>
        </div>
      </section>
      <section id="testimonials">
        <div className="container">
          <h2>Отзывы</h2>
        <AliceCarousel responsive = {    responsive    }>
    {
        testimonials.map((testimonial) => 
                <div className="sliderItem">
    
        <p> {testimonial.text}</p>
        

    </div>
        )
    }

   
</AliceCarousel>
        </div>
      </section>
      <section id="insta">
        <div className="container">
          <div className="left">
            <h2>Подпишитесь на наш Instagram аккаунт</h2>
            <p>Много полезных статей в нашем аккаунте</p>
            <a href='' className='linkContainer'><img src="/img/instagram.png"/><span>@lerba</span><button>Перейти и подписаться</button></a>
          </div>
          <div className="right">
          <img  src='/img/insta.png' />
          </div>
        </div>
      </section>
      <section id="qa">
        <div className="container">
          <h2>Вопросы и ответы</h2>
        <Accordions/>
        </div>
      </section>
      <section id="posts">
        <div className="container">
          <h2>Полезные статьи</h2>
          <div className='postsWrapper'>
              <div className="itemPost"><img src='https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'/>
          <p className='postTitle'>Lorem Ipsum is simply dummy text </p></div>
          <div className="itemPost"><img src='https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'/>
          <p className='postTitle'>Lorem Ipsum is simply dummy text </p></div>
          <div className="itemPost"><img src='https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'/>
          <p className='postTitle'>Lorem Ipsum is simply dummy text </p></div>
          </div>
        
        </div>
      </section>
    </>
  );
}

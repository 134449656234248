export const users = [
  {
    id: 1,
    name: "Даниил Нелюбин",
    gender: "man",
    img: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 7,
    price: 18000,
    education: "ENU",
    type: ["online"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Алкоголизм",
      "Наркомания",
      "РПП",
      "Игровая зависимость",
      "Зависимость от кредитов",
      "Клептомания",
      "Эмоциональная зависимость от другого человека",
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и вниманием",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Синдром дисморфофобии (не в рамках РПП)",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "ПТСР",
      "Шизотипические расстройства и шизофрения",
      "ОКР",
      "Биполярное расстройство",
      "Депрессия",
      "Расстройства личности",
      "Послеродовая депрессия",
    ],
  },
  {
    id: 2,
    name: "Айгерим Нартаева",
    gender: "woman",
    img: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 7,
    price: 18000,
    education: "ENU",
    type: ["online"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Разрыв отношений  развод",
      "Насилие в семье",
      "Утрата близкого человека",
    ],
  },
  {
    id: 3,
    name: "Венера Стадольник",
    gender: "woman",
    img: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 7,
    price: 18000,
    education: "ENU",
    type: ["personal", "online"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и вниманием",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Семейная терапия пары",
      "Разрыв отношений  развод",
      "Насилие в семье",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "Проблемы в школе",
      "Буллинг",
      "Проблемы с обучением",
      "Детская или подростковая агрессия",
      "Нежелание ходить в школу",
      "ПТСР",
      "ОКР",
      "Депрессия",
      "Послеродовая депрессия",
    ],
  },
  {
    id: 4,
    name: "Софья Чарлина",
    gender: "woman",
    img: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 7,
    price: 18000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["adult", "family", "couples", "child"],
    directions: [
      "Апатия",
      "Проблемы с мотивацией",
      "Проблемы с адаптацией",
      "Упадок сил",
      "Эмоциональное выгорание",
      "Проблемы в отношениях",
      "Проблемы в супружеской жизни",
      "Проблемы отношений с родителями",
      "Проблемы отношений с детьми",
      "Проблемы с самооценкой",
      "Проблемы с принятием",
    ],
  },
  {
    id: 5,
    name: "Айгерим Муканова",
    gender: "woman",
    img: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 7,
    price: 18000,
    education: "ENU",
    type: ["personal"],
    target_audience: ["couples"],
    directions: [
      "Апатия",
      "Проблемы с мотивацией",
      "Проблемы с адаптацией",
      "Упадок сил",
      "Эмоциональное выгорание",
      "Проблемы в отношениях",
      "Проблемы в супружеской жизни",
      "Проблемы отношений с родителями",
      "Проблемы отношений с детьми",
      "Проблемы с самооценкой",
      "Проблемы с принятием",
    ],
  },
  {
    id: 6,
    name: "Вадим Стадольник",
    gender: "man",
    img: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

    specialization: "Психолог, психотерапевт, гештальт-терапевт",
    about:
      "В работе внимательна, максимально включена в процесс. Уверена - универсальных решений нет. Каждый человек и случай индивидуален. Использую пространство терапии, знания, опыт, чувства и интуицию с целью помочь разобраться в сложностях и лучше понять себя",
    experiens: 7,
    price: 18000,
    education: "ENU",
    type: ["personal", "online"],
    target_audience: ["couples"],
    directions: [
      "Проблемы с мотивацией",
      "Проблемы с агрессией",
      "Апатия",
      "Проблемы с адаптацией в общество (новое место работы, переезд)",
      "Проблемы с концентрацией и вниманием",
      "Тревожно-фобические состояния",
      "Перепады настроения",
      "Прокрастинация",
      "Упадок сил",
      "Синдром эмоционального выгорания на работе",
      "Утрата близкого человека",
      "Проблемы в отношениях с парнем/девушкой",
      "Проблемы в супружеской жизни",
      "Проблема в отношениях с родителями",
      "Терапия состояния после измены",
      "Семейная терапия пары",
      "Разрыв отношений  развод",
      "Насилие в семье",
      "Комплексы в отношении себя или своего тела",
      "Проблемы с самооценкой",
      "Перфекционизм",
      "Отсутствие цели",
      "Проблемы в школе",
      "Буллинг",
      "Проблемы с обучением",
      "Детская или подростковая агрессия",
      "Нежелание ходить в школу",
      "ПТСР",
      "ОКР",
      "Депрессия",
      "Послеродовая депрессия",
    ],
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 2,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 3,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 4,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 5,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
  {
    id: 6,
    name: "Айгерим",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchange",
  },
];

export const faqs = [
  {
    id: 1,
    header: "What is Lorem Ipsum?",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "Where does it come from?",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "Why do we use it?",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "Where can I get some?",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];

export const categories = [
  {
    id: 1,
    name: "Алкоголизм",
    problems: [
      "я чувствую, что злоупотребляю алкоголем",
      "окружающие часто стали замечать, что я выпил алкоголь",
      "я пробовал отказаться от спиртного, но не получилось",
    ],
  },
  {
    id: 2,
    name: "Наркомания",
    problems: [
      "мне кажется, что у меня появилась проблема с наркотиками",
      "минимум 1 раз в неделю я принимаю марихуану (мефедрон, скорость и т.п.)",
      "я знаю не понаслышке, что такое трамадол, сомнол, тропикамид и т.п. Был период, когда я принимал аптечные препараты или сейчас принимаю",
    ],
  },
  {
    id: 3,
    name: "РПП",
    problems: [
      "я часто думаю о том, как ограничить себя в еде или сесть на диету",
      "бывают периоды, когда я вообще ничего не ем или после приемов пищи совершаю очищение желудка или кишечника (приступ рвоты, прием слабительных  диуретиков)",
      "я обвиняю себя за съеденную еду и возникают мысли, что все мои проблемы связаны с моим весом",
      "иногда я ем и не могу остановиться",
      "Я часто думаю, что мое тело неправильное и это изменить можно только снизив вес",
    ],
  },
  {
    id: 4,
    name: "Игровая зависимость",
    problems: [
      "Я думаю, что смогу заработать, поставив ставку или съездив в казино",
      "У меня есть долги (или были) после проигрышей в азартные игры",
      "У меня возникли проблемы на работе или в семье из-за того, что я занимал деньги и не смог их отдать, потому что проиграл",
      "Большая часть моих долгов связана с азартными играми",
      "Я пробовалпробовала перестать играть в азартные игры, но не получается",
    ],
  },
  {
    id: 5,
    name: "Сексоголизм",
    problems: [
      "Я перестал  перестала получать удовольствие от секса, хотя его очень много в моей жизни",
      "У меня возникли проблемы в семьи из-за того, что я часто изменял  изменяла своему партнеру",
      "Я чувствую, что слишком часто меняю половых партнеров и от этого становится неприятно",
    ],
  },
  {
    id: 6,
    name: "Зависимость от кредитов",
    problems: [
      "У меня много долгов, но я не могу перестать брать кредиты на разные покупки",
      "Значительная часть моей зарплаты уходит на погашение ежемесячного платежа",
      "Чем больше я зарабатываю, тем больше я беру кредитов и я не ощущаю, чтобы рос мой уровень жизни",
    ],
  },
  {
    id: 7,
    name: "Клептомания",
    problems: [
      "Иногда я могу украсть что-нибудь из магазина или дома друзей ради удовольствия",
      "У меня были проблемы из-за воровства в магазине или дома близких  друзей, но это меня не останавливает и меня это пугает",
    ],
  },
  {
    id: 8,
    name: "Эмоциональная зависимость от другого человека",
    problems: [
      "Я не могу перестать думать о другом человеке и постоянно из-за этого испытываю страдания",
      "Я часто смотрю о жизни другого человека в соцсетях, хотя мы с ней  ним давно расстались",
      "Я не могу поверить, что наши отношения с другим человеком закончились, возникают постоянные мысли о том, что все могло быть иначе",
    ],
  },
  {
    id: 9,
    name: "Созависимость",
    problems: [
      "Я устал  устала жить с зависимым человеком (алкоголизм, наркомания, игровая зависимость)",
      "Я постоянно испытываю тревогу от того, что он придет домой нетрезвым или я узнаю о новых долгах, стараюсь это проконтролировать, но ничего не получается",
      "Я не знаю что делать с тем, что мой близкий человека употребляет алкоголь  наркотики  играет в азартные игры",
    ],
  },
  {
    id: 10,
    name: "Самоповреждающее поведение",
    problems: [
      "Я совершаю порезы своего тела (или иные формы физического воздействия в отношении себя) и мне кажется, что мне становится гораздо лучше, но я думаю, что это опасно и нужно остановиться",
      "Я имею больше 10 порезов на своем теле, которые я сам  сама себе сделал сделала",
      "У меня были суицидальные попытки, я хочу перестать их совершать",
      "Я недавно совершил  совершила суицидальную попытку и мне нужна помощь",
    ],
  },
  {
    id: 11,
    name: "Проблемы с мотивацией",
    problems: [
      "Я не могу заставить себя что-либо делать",
      "Я знаю, что мне нужно сделать какую-то работу или учебу, но не хочется даже начинать и от этого возникает чувство вины",
      "Я не хочу учится или работать",
    ],
  },
  {
    id: 12,
    name: "Проблемы с агрессией",
    problems: [
      "Я замечаю, что иногда я не могу совладать со своей агрессией",
      "Я часто могу перестать контролировать себя и это переходит в конфликт или рукоприкладство",
      "Я могу избить другого человека (жену, мужа, ребенка, прохожего)",
    ],
  },
  {
    id: 13,
    name: "Апатия",
    problems: [
      "Меня ничего не радует",
      "Мне ничего не приносит удовольствия",
      "Жизнь серая и бесперспективная",
    ],
  },
  {
    id: 14,
    name: "Проблемы с адаптацией в общество (новое место работы, переезд)",
    problems: [
      "Я переехал  переехала из другой страны и не могу справиться с ощущением того, что я одинок",
      "Я вышел  вышла на новое место работы и мне трудно привыкнуть к новым людям и новому месту",
      "Я не могу справиться с тем, что меня перевели в другую школу",
    ],
  },
  {
    id: 15,
    name: "Проблемы с концентрацией и вниманием",
    problems: [
      "У меня не получается сосредоточиться на важных задачах или делах",
      "Во время разговора я могу думать о посторонних вещах и совсем не слушать собеседника, это сильно затрудняет общение с другими людьми",
      "Я не могу сосредоточиться на фильмах, книгах, учебе или работе, возникает ощущение, что я просто все забываю",
    ],
  },
  {
    id: 16,
    name: "Тревожно-фобические состояния",
    problems: [
      "У меня часто возникает необоснованное чувство тревоги по разным вещам",
      "Я испытываю сильный страх из-за каких-то конкретных событий и не могу справиться с этим",
      "Меня беспокоит, что я часто думаю об определенных событиях и не могу остановить эти мысли",
    ],
  },
  {
    id: 17,
    name: "Перепады настроения",
    problems: [
      "Я замечаю, что слишком резко меняется мое настроение от необоснованной радости до упадка и грусти",
      "Какая-нибудь мелочь может вывести из себя и вызвать агрессию или заставить грустить",
      "Из-за перепадов в настроении мне трудно дается выполнение работы или учебы",
    ],
  },
  {
    id: 18,
    name: "Проблемы с прокрастинацией",
    problems: [
      "Я постоянно откладываю дела на самый последний момент",
      "Я часто не успеваю сделать работу вовремя, в силу чего возникает чувство вины",
      "Я могу целый день потратить на мысли о незавершенном задании, которое необходимо сделать, но так и не сажусь его делать",
    ],
  },
  {
    id: 19,
    name: "Упадок сил",
    problems: [
      "Я испытываю моральное, психологическое и физическое истощение",
      "Не хватает сил даже встать с кровати и поесть",
      "Хочется просто ни о чем не думать и закрыться одеялом",
    ],
  },
  {
    id: 20,
    name: "Синдром эмоционального выгорания на работе",
    problems: [
      "Я не был в отпуске очень давно, сильно устал от работы и не могу даже заставить себя сделать элементарные дела по дому",
      "Любое упоминание о работе вызывает неприязнь или раздражение",
      "Когда я начинаю думать, что завтра на работу, появляется сильное нежелание туда идти, возникает упадок настроения и сил",
    ],
  },
  {
    id: 21,
    name: "Утрата близкого человека",
    problems: ["Я не могу справиться со смертью близкого мне человека"],
  },
  {
    id: 22,
    name: "Проблемы в отношениях с парнем/девушкой",
    problems: [
      "В отношениях с парнем  девушкой мне кажется, мы не пониманием друг друга, создается ощущение, что мы говорим на разных языках",
      "У нас частые конфликты из-за мелочей с моим (моей) парнем (девушкой)",
      "Я не знаю нужно ли мне продолжать отношения с парнем  девушкой",
    ],
  },
  {
    id: 23,
    name: "Проблемы в супружеской жизни",
    problems: [
      "Я устал  устала от постоянных конфликтов в супружеской жизни",
      "Я не могу терпеть больше контроль со стороны супруга  супруги",
      "У нас с супругом  супругой давно не было секса (или иные проблемы в сексуальной жизни)",
    ],
  },
  {
    id: 24,
    name: "Проблема в отношениях с родителями",
    problems: [
      "Родители постоянно сравнивали (сравнивают) меня с другими людьми, что очень раздражает и вызывает сильную обиду",
      "Родители не могут перестать меня контролировать, они принимают решения за меня (кем стать? Как жить? На кого учиться? И т.п.)",
      "Я боюсь говорить о проблемах своей маме (отцу), хотя многое меня не устраивает",
      "Я испытываю ненависть по отношению к отцу (маме)",
      "Родители часто манипулируют своим физическим состоянием, чтобы заставить меня что-либо сделать",
    ],
  },
  {
    id: 25,
    name: "Терапия состояния после измены",
    problems: [
      "Мне изменил мой партнер, я не могу справиться со своим состоянием (депрессия, агрессия, ненависть, нежелание прощать, непринятие ситуации, разрушение самооценки и проч.)",
    ],
  },
  {
    id: 26,
    name: "Патологическая ревность",
    problems: [
      "Мой партнер постоянно меня преследует, следит за мной, контролирует, смотрит в телефон, подозревает в измене, я устал  устала от такой жизни",
      "Я подозреваю своего партнера в измене и есть постоянные мысли о том, что он  она делает, с кем встречается, куда ходит и т.д. Я хочу справиться с собой и своим состоянием, потому что это приводит к конфликтам",
    ],
  },
  {
    id: 27,
    name: "Семейная терапия пары",
    problems: [
      "У нас проблема с партнером, которую мы бы хотели решить с семейным психологом вдвоем",
    ],
  },
  {
    id: 28,
    name: "Разрыв отношений  развод",
    problems: [
      "Я не могу поверить и принять, что мы развелись и что больше не можем быть вместе",
      "На фоне развода и завершения отношений у меня развилась депрессия",
    ],
  },
  {
    id: 29,
    name: "Насилие в семье",
    problems: [
      "Меня избивает муж  жена, я хочу найти способ — это прекратить",
      "Меня систематически избивали родители, я хочу справиться с этими воспоминаниями и болью",
    ],
  },
  {
    id: 30,
    name: "Синдром дисморфофобии (не в рамках РПП)",
    problems: [
      "Я не принимаю себя или часть своего тела",
      "Мое тело вызывает у меня ненависть или отвращение",
      "Я хочу сделать себе множество разных косметических и пластических процедур, потому что я не могу на себя смотреть",
    ],
  },
  {
    id: 31,
    name: "Комплексы в отношении себя или своего тела",
    problems: [
      "Меня беспокоит мысль о размере тех или иных частей своего тела (нос, ноги, грудь, половые органы)",
      "Мне стыдно показывать те или иные части своего тела, потому что они неправильные по форме (грудь, рост, вес, нос, глаза и т.п.)",
      "Я постоянно испытываю стеснение из-за своего голоса (носа, глаз, родинки, ассиметрии грудей и т.п.)",
    ],
  },
  {
    id: 32,
    name: "Проблемы с самооценкой",
    problems: [
      "Я постоянно ощущаю, что я хуже других людей",
      "Я хуже делаю что-либо, в сравнении с другими",
      "Я не решаюсь начать что-либо делать, т.к. искренне считаю, что я не справлюсь с этим",
    ],
  },
  {
    id: 33,
    name: "Перфекционизм",
    problems: [
      "Я должен делать все идеально, я не начну что-либо делать, если я уверен, что не смогу сделать это идеально",
      "Я могу потратить много времени на какую-либо незначительную задачу, доводя ее до идеала из-за чего часто страдают сроки выполнения работы, и я не успеваю сделать ее вовремя или не успеваю сделать другие вещи",
      "Я часто страдаю от того, что я не смог сделать задачи идеально и наткнулся на критику со стороны других людей",
    ],
  },
  {
    id: 34,
    name: "Отсутствие цели",
    problems: [
      "Я не знаю, чем мне занять себя",
      "Я не знаю кем мне стать и какую профессию выбрать",
      "Я не вижу себя в будущем и испытываю сильную тревогу по этому поводу",
    ],
  },
  {
    id: 35,
    name: "Проблемы в школе",
    problems: [
      "У моего ребенка (у меня) проблемы в школе (проблемы с учителем, проблемы с одноклассниками и т.п.)",
    ],
  },
  {
    id: 26,
    name: "Буллинг",
    problems: [
      "Мой ребенок столкнулся с буллингом (унижениями, оскорблениями, притеснением) в школе и из-за этого возникли разные психологические проблемы – нарушение самооценки, мотивации, появились страхи и т.п.",
    ],
  },
  {
    id: 37,
    name: "Проблемы с обучением",
    problems: [
      "Мой ребенок не хочет учиться в школе",
      "Есть трудности в освоении тех или иных предметов",
      "Стоит на гране исключения из школы",
    ],
  },
  {
    id: 38,
    name: "Детская или подростковая агрессия",
    problems: [
      "Ребенок постоянно дерется с другими детьми в школе",
      "Мой ребенок часто кричит на других детей в классе",
      "Мой ребенок может грубить учителям в школе, родителям или другим взрослым",
    ],
  },
  {
    id: 39,
    name: "Нежелание ходить в школу",
    problems: [
      "Мой ребенок отказался ходить в школу, и я не знаю причины этого",
      "Мой ребенок отказался ходить в школу, я знаю причины, но ничего не могу сделать",
    ],
  },
  {
    id: 40,
    name: "Непослушание",
    problems: [
      "Я не могу справится с подростком, он «отбился от рук»",
      "Моя дочь  мой сын перестали вообще что-либо делать и слушать меня",
      "У меня очень тяжелые и натянутые отношения со своим ребенком",
    ],
  },
  {
    id: 41,
    name: "ПТСР",
    problems: [
      "Я не могу пережить травмирующее событие, постоянно об этом вспоминаю и это вызывает сильную психологическую боль",
    ],
  },
  {
    id: 42,
    name: "Шизотипические расстройства и шизофрения",
    problems: [
      "Мне поставили диагноз «шизофрения», я хочу научиться с этим жить",
      "Мне кажется у меня шизофрения",
    ],
  },
  {
    id: 43,
    name: "ОКР",
    problems: [
      "Мне поставили диагноз обсессивно-компульсивное расстройство, я хочу начать дополнительно психотерапию",
      "Мне кажется у меня обсессивно-компульсивное расстройство",
    ],
  },
  {
    id: 44,
    name: "Биполярное расстройство",
    problems: [
      "Мне поставили диагноз биполярное расстройство, я хочу дополнительно пройти курс психотерапии, чтобы научиться с этим справляться",
      "Мне кажется у меня биполярное расстройство",
    ],
  },
  {
    id: 45,
    name: "Депрессия",
    problems: [
      "Мне поставили диагноз депрессия, я хочу попробовать изменить свое состояние, я очень от этого устал  устала",
      "Мне кажется у меня депрессия",
    ],
  },
  {
    id: 46,
    name: "Послеродовая депрессия",
    problems: [
      "После рождения ребенка я ничего не хочу делать, я от всего устала",
      "Иногда возникает мысль, что это не мой ребенок и я не хочу с ним проводить время",
      "После родов я не могу нормально жить, иногда возникают мысли о самоубийстве",
    ],
  },
  {
    id: 47,
    name: "Ипохондрическое расстройство",
    problems: [
      "Я думаю, что я точно болен каким-то страшным заболеванием",
      "Я очень много времени трачу на изучение разных болезней и неизбежно нахожу признаки болезней у себя",
      "Я сильно боюсь умереть от онкологии (вич, гепатита или другого заболевания), из-за чего я постоянно посещаю разных врачей, могу посещать одних и тех же врачей по несколько раз (вдруг они чего-то «не дообследовали»)",
    ],
  },
  {
    id: 48,
    name: "ЛГБТ",
    problems: [
      "Я чувствую себя очень одиноким  одинокой из-за того, что не могу никому рассказать о своей сексуальной ориентации",
      "Я всю жизнь сталкивался  сталкивалась с осуждением со стороны общества и гомофобией, что привело меня к появлению больших проблем в самооценке и принятии себя",
      "Я ненавижу себя за то, что я испытываю влечение к представителям своего пола, я хотел  хотела бы пройти психотерапию, чтобы снизить эту ненависть к себе и найти способы принять себя",
    ],
  },
];

import React, { useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './psSlider.css'
import {users} from '../../data'
import { Link } from "react-router-dom";

export default function PsSlider() {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 4 },
    };
  return (
    <AliceCarousel responsive = {    responsive    } mouseTracking infinite>
    {
        users.map((user) => 
               <div className="sliderItem">
        <div className="sliderImg" style={{position:"relative", background: `url(${user.img})` , backgroundSize: "cover" }} ><span className="experiens">Опыт: {user.experiens} лет</span></div>
        <p>{user.name} {user.lastname}</p>
        <span className="price">{user.price} тг</span>
        <Link to={'/psychologists/' + user.id} >  <button className="itemBtn">Подробнее</button></Link>
       
    </div>
        )
    }

   
</AliceCarousel>
  )
}

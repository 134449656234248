import React, { useRef, useState } from 'react'
import { faqs } from '../../data';
import './accordion.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;
  
  
  return (
    <div className="rc-accordion-card">
    <div className="rc-accordion-header">
        <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
            <h5 className="rc-accordion-title">{header}</h5>
            <KeyboardArrowDownIcon className='rc-accordion-icon'/>
        </div>
    </div>
    <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
        active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
    }>
        <div className="rc-accordion-body">
            <p className='mb-0'>{text}</p>
        </div>
    </div>
</div>
  )
}


export default function Accordions(props) {
     
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    return (
        <>
        <div className="container-fluid mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-8 mt-2">
                    <div className="card">
                        <div className="card-body">
                           {faqs.map((faq, index) => {
                                 return (
                                        <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )

}
import './header.scss';
import logo from '../../logo.svg';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="header">
      <div className="wrapper">
        <div className="headerLeft">
          <img src={logo} className="logo" alt="logo" />
        </div>
        <div className="headerRight">
          <div className="headerMenu">
            <ul>
              <li>
              <Link to='/'>
                 Главная
                </Link>
              </li>
              <li>
              <Link to='/#why'>
                 Почему мы
                </Link>
               
              </li>
              <li>
              <Link to='/#psychologists'>
              Психологи
                </Link>
              
              </li>
              <li>
                <a href="#">Как записаться</a>
              </li>
              <li>
                <a href="#">Отзывы</a>
              </li>
              <li>
                <Link to='/business'>
                  Для бизнеса
                </Link>
           
              </li>
            </ul>
            <button className="headerBtn" onClick={() => {}}>
              Онлайн консультация
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

import React, { useContext, useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Counter from "./Counter";
import { MultiStepContext } from "../../StepContext";
import { Link } from "react-router-dom";
import { categories, users } from "../../data";

function Form9() {
  const { setStep, filteredUsers, selectedCheckboxs, changeData } =
    useContext(MultiStepContext);

  const Checkboxs = selectedCheckboxs;
  // const usersThird = filteredUsers; // получаю список из второй формы
  const usersNinth = filteredUsers;
  const [ninthFilteredUsers, setNinthFilteredUsers] = useState(usersNinth); // временное состояние
  const [newCheckboxs, setNewCheckboxs] = useState([]); //Выбранные чекбоксы
  const [catArray, setCatArray] = useState([]);

  function convertCheckboxes(array) {
    const catAr = array.map((box) => {
      const category = categories.find((cat) => cat.problems.includes(box));
      return category ? category.name : box;
    });
    return catAr.filter((x, i) => catAr.indexOf(x) === i);
  }

  function filterUsersByType(array) {
    return usersNinth.filter((user) =>
      array.every((type) => user.directions.includes(type))
    );
  }

  function onChangeValue(e) {
    const selectedValue = e.target.value; // записываю выбранное значение

    const isChecked = newCheckboxs.includes(selectedValue);
    if (isChecked) {
      const updatedFilters = newCheckboxs.filter(
        (filter) => filter !== selectedValue
      );
      setNewCheckboxs(updatedFilters);
    } else {
      setNewCheckboxs([...newCheckboxs, selectedValue]);
    }
    setCatArray(convertCheckboxes(newCheckboxs));

    //const filtered3 = filterUsersByType(catArray);
    // setThirdFilteredUsers(filtered3);
  }

  useEffect(() => {
    const catArray = convertCheckboxes(newCheckboxs);
    setCatArray(catArray);

    const filteredUsers = filterUsersByType(catArray);
    setNinthFilteredUsers(filteredUsers);
  }, [newCheckboxs]);

  console.log("newCheckboxs: ", newCheckboxs);
  console.log("catArray: ", convertCheckboxes(selectedCheckboxs));
  console.log("ninthFilteredUsers: ", ninthFilteredUsers);
  console.log("usersNinth: ", usersNinth);

  return (
    <div className="container flexColumn">
      <button className="back" onClick={() => setStep(3)}>
        Назад
      </button>
      <h2>С какой проблемы хотели бы начать?</h2>

      <FormGroup>
        {Checkboxs.map((filter) => (
          <div className="itemProblem">
            <FormControlLabel
              key={filter.id}
              control={<Checkbox />}
              value={filter}
              label={filter}
              checked={newCheckboxs.includes(filter)}
              onChange={(e) => onChangeValue(e)}
            />
          </div>
        ))}
      </FormGroup>
      <div className="countContainer">
        <Counter filteredUsers={ninthFilteredUsers} />
        <Link to="/result">
          <button onClick={() => changeData(9, ninthFilteredUsers)}>
            Продолжить
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Form9;

import React, { useContext } from "react";
import logo from "../../logo.svg";
import { Link } from "react-router-dom";
import { MultiStepContext } from "../../StepContext";

export default function () {
  const { filteredUsers, resetSearch } = useContext(MultiStepContext);
  return (
    <div>
      <div className="header">
        <Link to="https://lerba.kz/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
      </div>
      <div className="titleContainer">
        <h2>Результаты поиска</h2>
        <Link to="/search">
          <button onClick={() => resetSearch()}>Сбросить</button>
        </Link>
      </div>
      <div className="results">
        {filteredUsers.map((user) => (
          <div className="resultItem">
            <div
              className="sliderImg"
              style={{
                position: "relative",
                background: `url(${user.img})`,
                backgroundSize: "cover",
              }}
            >
              <span className="experiens">Опыт: {user.experiens} лет</span>
            </div>
            <p>
              {user.name} {user.lastname}
            </p>
            <span className="price">{user.price} тг</span>
            <Link to={"/psychologists/" + user.id}>
              {" "}
              <button className="itemBtn">Подробнее</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

import './specialist.css';
import { users } from '../../data';
import { useParams } from 'react-router-dom';

export default function Specialist() {
  const params = useParams()


 
  const user = users.find((item) => item.id == params.id)
  console.log(user)

  return <div className="container userContainer">
    <div className="photoWrapper"><img src={user.img}/>
    <button>Записаться</button>
    </div>
    <div className="dataWrapper">
      <p className="UserTitle"> { user.name } {user.lastname}</p>
  <p className="specialization">{ user.specialization }</p>
  <p className="specialization">{ user.about }</p>
  <p className='experiens'>Опыт: {user.experiens} лет</p>
  <p className='price'>Стоимость: {user.price} тг</p>
  <p className='education'>Образование: {user.education} </p>
  <p className="Work">С чем работает</p>
  <ul>
   {
   user.directions.map((item) => 
    <li>{item}</li>
   )} 
  </ul>
    </div>
  
  </div>;
}

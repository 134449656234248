import React, { useContext, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { MultiStepContext } from "../../StepContext";
import { users } from "../../data";
import Counter from "./Counter";

export default function Form1() {
  const { changeData } = useContext(MultiStepContext);
  const usersFirst = users;
  const [FirstFilteredUsers, setFirstFilteredUsers] = useState(usersFirst);

  function filterUsersByType(selectedType) {
    if (selectedType === "both") {
      // Если выбран "Оба", показываем всех пользователей
      return users;
    } else {
      // Фильтруем пользователей по типу
      return users.filter((user) => user.type.includes(selectedType));
    }
  }

  function onChangeValue(event) {
    const selectedType = event.target.value;
    // Фильтрация пользователей по выбранному типу
    const filtered = filterUsersByType(selectedType);

    console.log("FilteredUserFirst: ", filtered);
    setFirstFilteredUsers(filtered);
  }
  return (
    <div className="container flexColumn">
      <h2>Тип встречи</h2>
      <FormControl>
        <RadioGroup>
          <div className="item">
            <FormControlLabel
              value="online"
              control={<Radio />}
              label="Онлайн"
              onChange={onChangeValue}
            />
          </div>
          <div className="item">
            <FormControlLabel
              value="personal"
              control={<Radio />}
              label="Лично"
              onChange={onChangeValue}
            />
          </div>
          <div className="item">
            <FormControlLabel
              value="both"
              control={<Radio />}
              label="Не важно"
              onChange={onChangeValue}
            />
          </div>
        </RadioGroup>
      </FormControl>
      <div className="countContainer">
        <Counter filteredUsers={FirstFilteredUsers} />

        <button onClick={() => changeData(2, FirstFilteredUsers)}>Далее</button>
      </div>
    </div>
  );
}

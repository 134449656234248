import './footer.css';
import logo from '../../logo.svg';

export default function Footer() {
  return <footer>
    <div className='container'>
      <div className='widget'>
    <img src={logo} className="logo" alt="logo" />
    </div>
    <div className='widget'>
    <a>Подобрать психолога</a>
    <a>Вопросы и ответы</a>
    <a>Конфиденциальность</a>
    </div>
    <div className='widget'>
    <p>Техническая поддержка</p>
    <a href="">support@lebra,kz</a>
    </div>
    </div>
    
  </footer>;
}

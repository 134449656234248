import React from "react";
import { users } from "../../data";

function Test() {
  const selectedTypes = [
    "Проблемы с мотивацией",
    "Проблемы с агрессией",
    "Апатия",
    "Проблемы в школе",
  ];

  // Создаем новый массив, в который будут записаны объекты с совпадениями
  const filtered3 = users.filter((user) =>
    selectedTypes.every((type) => user.directions.includes(type))
  );

  console.log(filtered3);

  return <div className="container">test</div>;
}

export default Test;
